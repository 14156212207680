












































import { Component, PropSync, Vue } from 'vue-property-decorator';

import FlagshipProfilingVideoViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-video-view-model';

@Component({ name: 'FlagshipProfilingVideo' })
export default class FlagshipProfilingVideo extends Vue {
  @PropSync('videoSrcMp4', { type: String, required: true })
  private synced_video_src_mp4!: string;

  @PropSync('videoSrcWebm', { type: String, required: true })
  private synced_video_src_webm!: string;

  @PropSync('videoTitle', { type: String, required: true })
  private synced_video_title!: string;

  flagship_video_view_model = Vue.observable(
    new FlagshipProfilingVideoViewModel(this),
  );
}
