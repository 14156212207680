import TYPES from '@/types';
import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipProfilingVideoViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship-profiling-video';

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get exists_video_src() {
    // eslint-disable-next-line no-prototype-builtins
    return (this.view.$props.hasOwnProperty('videoSrcMp4') && this.view.$props.videoSrcMp4)
      // eslint-disable-next-line no-prototype-builtins
      || (this.view.$props.hasOwnProperty('videoSrcWebm') && this.view.$props.videoSrcWebm);
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }
}
